@font-face {
    font-family: 'Brexton';
    src: url('brexton.woff2') format('woff2'), url('brexton.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue-ext';
    src: url('HelveticaNeue-Roman.woff2') format('woff2'), url('HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue-ext-bold';
    src: url('HelveticaNeue-Bold.woff2') format('woff2'), url('HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
